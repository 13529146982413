import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { ADD_USER } from '../../utils/mutations';
import { QUERY_USER_BY_EMAIL } from '../../utils/queries';
import "./register.css";

const Register = () => {
    const [formState, setFormState] = useState({
        email: '',
        password: ''
    });
    const [addUser] = useMutation(ADD_USER);
    const { loading, error, data } = useQuery(QUERY_USER_BY_EMAIL, { variables: { email: formState.email } });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormState({
            ...formState,
            [name]: value
        });
    }
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        console.log(formState);
        console.log(data)
        if (data.user) {
            console.log('username exists');
            alert('Username exists');
        } else {
            try {
                const { data } = await addUser({
                    variables: { ...formState }
                });
            } catch (e) {
                console.error(e);
            }
            window.location.replace('/');
        }

    }

    return (
        <form className="register-form" onSubmit={handleFormSubmit}>
            <input className="form-input"
                placeholder="Your email"
                name="email"
                type="email"
                value={formState.email}
                onChange={handleChange} />
            <input className="form-input"
                placeholder="Password"
                name="password"
                type="password"
                value={formState.password}
                onChange={handleChange} />
            <button
                className="btn"
                type="submit"
            >
                Submit
            </button>
        </form>
    );
}

export default Register;