import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';


// Pages
import Home from '../src/pages/Home/index';

// Utils
import Auth from './utils/auth';

var uriString = 'http://localhost:4000/graphql';

if (process.env.NODE_ENV === 'production') {
  uriString = '/graphql';
}
const httpLink = createHttpLink({
  uri: uriString
});

// Construct request middleware that will attach
// the JWT token to every request as an 
// 'authorization' header
const authLink = setContext((_, { headers }) => {
  // Get the authentication token from local storage
  // if it exists
  // const token = localStorage.getItem('id_token');
  // Get the authentication token from cookie
  // if it exists
  const token = Auth.getToken();
  // Return the headers to the context so httpLink
  // can read them
  return {
      headers: {
          ...headers,
          authorization: token ? `Bearer ${token}`: ''
      }
  };
});

const client = new ApolloClient({
  // Set up our client to execute the 'authLink'
  // middleware prior to making the request to
  // the GraphQL API
  connectToDevTools: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache().restore({})
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </Router>
    </ApolloProvider>
  );
}

export default App;
