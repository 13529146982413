import './headerpublic.css';



const HeaderPublic = () => {

    return (
        <div className="header">

            <div className="header-container">
                <h1 className="header-title">OnusTask</h1>
                
            </div>

        </div>
    )
}

export default HeaderPublic;