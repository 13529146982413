
import Header from '../../components/Header';
import TaskList from '../../components/TaskList';
import HeaderPublic from '../../components/HeaderPublic';
import SignIn from '../../components/SignIn';
import Auth from '../../utils/auth';
import HomeContent from './HomeContent';

const Home = () => {
    const loggedin = Auth.loggedIn();


    
    if (loggedin) {
        return (
            <>
                <main>
                    <Header />
                    <TaskList />
                </main>
            </>
        );
    } else {
        return (
            <>
                <main>
                    <HeaderPublic />
                    <SignIn />
                    <HomeContent />
                    
                </main>
            </>
        );
    }
    
}

export default Home;